<template>
  <v-data-table :headers="headers" :items="users" sort-by="no" dense @click:row="detailItem">
    <template v-slot:top>
      <v-toolbar flat>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="success" v-on="on" dark rounded :to="{ name: 'Create User' }">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Create</span>
        </v-tooltip>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ users.map(x => x.id).indexOf(item.id) + 1 }}
    </template>
    <template v-slot:item.name="{ item }">
      {{ item.firstName + " " + item.lastName }}
    </template>
    <template v-slot:item.status="{ item }">
      {{ !item.isAccountLocked ? "Actived" : "Deactived" }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Role",
        value: "role",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Mobile",
        value: "mobile",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  computed: {
    ...mapState("user", ["users"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      return this.$store.dispatch("user/getAll");
    },
    detailItem(item) {
      this.$router.push({ name: "Detail User", params: { id: item.id } });
    },
  },
};
</script>

<style></style>
